<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-row class="auth-inner m-0">

      <!-- Login-->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-0"
      >
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div
            style="text-align:center; margin-top:-50px;"
          >
            <!--
            <b-img
              style="width:150px;"
              :src="require(`@/assets/images/partner/${partner}/logo/logo.png`)"
            />
            -->
            <h5>backoffice.ladestellenbonus.at</h5>
            <br><br><br><br>
          </div>
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Login
          </b-card-title>
          <b-card-text class="mb-2">
            Bitte melden Sie sich in Ihrem<br> Backoffice-Konto an
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="benutzer@ladestellenbonus.at"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Passwort</label>
                <!--
                 <b-link :to="{name:'forgot-password'}">
                    <small>Passwort vergessen?</small>
                  </b-link>
                  -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Passwort"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-alert
                v-if="showAlert"
                variant="danger"
                show
              >
                <h4 class="alert-heading">
                  Fehler
                </h4>
                <div class="alert-body">
                  <span>{{ alertText }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <br>
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Anmelden
              </b-button>
            </b-form>
          </validation-observer>
          <!--
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
          -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import jwtDecode from 'jwt-decode'
import store from '@/store/index'
//  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

localize('de')
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      showAlert: false,
      alertText: '',
      partner: 'ladestellenbonus',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    getUserAbility() {
      const managerAbility = [
        {
          action: 'manage',
          subject: 'Users',
        },
        {
          action: 'manage',
          subject: 'Dashboard',
        },
        {
          action: 'manage',
          subject: 'Auth',
        },
      ]

      return managerAbility
    },
    getMangerAbility() {
      const managerAbility = [
        {
          action: 'manage',
          subject: 'Dashboard',
        },
        {
          action: 'manage',
          subject: 'Users',
        },
        {
          action: 'manage',
          subject: 'SolarPlant',
        },
        {
          action: 'manage',
          subject: 'Auth',
        },
      ]

      return managerAbility
    },
    getAdminAbility() {
      const adminAbility = [
        {
          action: 'manage',
          subject: 'all',
        },
      ]

      return adminAbility
    },
    validationForm() {
      //  This is temp only, do logic first!
      //  this.$router.push('/')
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.showAlert = false

          useJwt.login({
            username: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log('response.data', response.data)

              if (response.status === 200) {
                useJwt.setToken(response.data.accessToken)
                useJwt.setRefreshToken(response.data.refreshToken)

                const token = response.data.accessToken
                const decodedToken = jwtDecode(token)

                console.log(decodedToken)

                let userLevel = 'user'

                let userAbility = []
                if (decodedToken.realm_access.roles.includes('user')) {
                  userAbility = this.getUserAbility()
                }

                if (decodedToken.realm_access.roles.includes('manager')) {
                  userAbility = this.getMangerAbility()
                  userLevel = 'manager'
                }

                if (decodedToken.realm_access.roles.includes('admin')) {
                  userAbility = this.getAdminAbility()
                  userLevel = 'admin'
                }

                const userData = {
                  //  id: decodedToken.sub,
                  //  uid: decodedToken.sid,
                  //  id: response.data.userId,
                  //  cid: response.data.carId,
                  //  fullName: `${decodedToken.given_name} ${decodedToken.family_name}`,
                  //  username: decodedToken.preferred_username,
                  avatar: '',
                  fullName: decodedToken.email,
                  username: decodedToken.email,
                  email: decodedToken.email,
                  ability: userAbility,
                  //  firstName: decodedToken.given_name,
                  //  lastName: decodedToken.family_name,
                  userLevel,
                }

                window.$cookies.set('userData', userData, '1D')

                // Updating user ability in CASL plugin instance
                this.$ability.update(userData.ability)

                //  does not reload axios settings
                //  this.$router.push('/')

                //  force reload to reinit auth token -- temp fix
                this.$router.go('/')
              }
            })
            .catch(error => {
              console.log(error)
              this.showAlert = true
              this.alertText = 'Die angegebene Kombination aus E-Mail-Adresse und Passwort ist ungültig.'
            })
        }
      })
    },
  },
  setup() {
    const appIdElem = document.getElementById('app')
    let backgroundImage = ''
    if (typeof (window.$cookies.get('partner')) !== 'undefined') {
      backgroundImage = require(`@/assets/images/partner/ladestellenbonus/backgorund/auth.png`)
      appIdElem.style.backgroundImage = `url('${backgroundImage}')`
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
